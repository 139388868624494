
const carousel = document.querySelector('.social-posts__posts');

if (carousel) {
    new Swiper(carousel, {
        slidesPerView: 'auto',
        loop: true,
        centeredSlides: true,
        spaceBetween: 20,
        navigation: {
            nextEl: '.social-posts__nav-next',
            prevEl: '.social-posts__nav-prev',
        },
    });
}